@import '../../../styles/vars';

.input {
	position: relative;

	input {
		padding: 0 75px 0 20px;
		width: 100%;
		height: 45px;
		border-radius: 30px;
		border: 1px solid $color-border3;
		opacity: 0.7;

		&::placeholder {
			letter-spacing: normal;
			line-height: normal;
			font-size: 14px;
			font-family: $WorkSans;
		}
	}
}
