@import '../../../../shared/styles/vars';

.search {
	position: relative;
	margin-bottom: 20px;
}
.input {
	padding: 0 15px;
	height: 35px;
	width: 100%;
	border: 1px solid $color-border2;
	background: $color-white;
	opacity: .7;
}
.btn_close {
	position: absolute;
	top: 50%;
	right: 13px;
	transform: translateY(-50%);
}
