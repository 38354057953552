@import '../../../styles/vars';

.slider {
	background-color: transparent;
}

.arrow {
	position: absolute;
	top: 225px;
	left: 338px;
	display: inline-flex;
	padding: 0;
	width: 36px;
	height: 40px;
	opacity: 0;
	@include transition-all;

	&.active {
		opacity: 1;
	}

	svg {
		width: inherit;
		height: inherit;
	}
}

.arrow_prev {
	left: -5px;
	transform: scale(-1) translateX(12px);

	&.active {
		transform: scale(-1) translateX(100%);
	}
}
.arrow_next {
	transform: translateX(-20px);

	&.active {
		transform: translateX(0);
	}
}
