@import '../../../shared/styles/vars';

.box {
	display: flex;
	align-items: center;
	gap: 20px;
}

.input_box {
	display: flex;
	align-items: center;
	gap: 15px;
}

.input_label {
	font-size: 14px;
	line-height: 24px;
	font-weight: $weight-medium;

	&_none {
		display: none;
	}
}

.input {
	max-width: 130px;
	width: 100%;
	height: 42px;
	padding: 0 12px;
	border: 1px solid $color-border;
	border-radius: 5px;

	&_straight {
		border-radius: 0;
	}
}

.btn_add {
	text-transform: capitalize;
}
