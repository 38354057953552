@import '../../../../styles/vars';

.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;
	height: 44px;
	line-height: 44px;
	border-radius: 10px;
	color: $color-white;
	background-color: $color-primary;
	@include transition-all;

	&:hover {
		background-color: $color-dark;
	}

	&:disabled {
		background-color: $color-border;
		cursor: default;

		&:hover {
			background-color: $color-border;
		}
	}
}

// type
.subscribe {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 15px;
	height: 100%;
	border-radius: 0 30px 30px 0;

	& > * {
		text-transform: uppercase;
		line-height: 22px;
	}
}

.search {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 20px;
	height: 100%;
	border-radius: 0 30px 30px 0;

	& svg path {
		fill: $color-white;
	}
}

.submit {
	height: 42px;
	line-height: 42px;
	font-size: 16px;
	font-weight: 500;
	border-radius: $default-border-radius;
}

.text {
	padding: 0;
	height: 28px;
	line-height: 28px;
	border: none;
	background-color: transparent;

	&:hover {
		background-color: transparent;

		& > * {
			color: $color-primary;
		}
	}

	&:focus {
		background-color: transparent;
	}
}

.filter {
	height: 30px;
	border-radius: 30px;
	background-color: $color-dark;

	&:hover {
		background-color: $color-primary;
	}
}

.form {
	padding: 5px 15px 3px;
	height: 32px;
	line-height: 20px;
	text-transform: uppercase;
	border-radius: 5px;
	background-color: $color-dark;

	&:hover {
		background-color: $color-primary;
	}
}

.border_none {
	border-radius: 0;
}

.focus {
	background-color: $color-dark;
}

.post {
	border-radius: 5px;
}

.auth {
	text-transform: uppercase;
	padding: 5px 20px;
	height: 34px;
	border-radius: 20px;
}

.secondary {

	border: 1px solid $color-border;
	border-radius: $default-border-radius;
	background-color: $color-white;

	&:hover {
		border-color: $color-border-hover;
		background-color: $color-white;
	}
}
