@import '../../shared/styles/vars';

.product {
	display: flex;
	gap: 24px;
}

.img {
	max-width: 559px;
	max-height: 559px;
	width: 100%;
	height: 100%;
	border: 1px solid $separator;
}

.product_img {
	width: 100%;
	height: 100%;
}

.name {
	line-height: 25px;
	font-size: 22px;
	font-weight: $weight-normal;
}

.price {
	justify-content: flex-start;
}

.content {
	max-width: 557px;
}

.title {
	font-size: 22px;
	line-height: 20px;
}

.btn {
	&:hover {
		.heart_broken {
			opacity: 1;
		}
	}
}

.heart {
	cursor: pointer;

	path {
		fill: $color-primary;
	}
}

.heart_broken {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	@include transition-all;

	path {
		fill: $color-error;
	}
}

.category {
	display: flex;
}

.tag {
	margin-left: 10px;

	&:hover {
		color: $color-primary;
	}
}

.specifications {
	padding: 20px 30px 30px 30px;
	border: 1px solid $color-border2;
}

.tabs {
	margin-bottom: 40px;
}
