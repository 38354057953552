@import '../../../../shared/styles/vars';

.footer {
	display: flex;
}

.img {
	margin-bottom: 24px;
	width: 118px;
	height: 28px;
}

.meta {
	width: 31%;

	div {
		margin-bottom: 6px;
		line-height: 22px;
		font-size: 14px;

		&:nth-child(2) {
			margin-bottom: 22px;
		}

		a {
			margin-left: 5px;
			font-weight: 400;
		}
	}
}

.address,
.email,
.phone {
	font-weight: 600;

	a:hover {
		color: $color-primary;
	}
}

.list {
	display: flex;
	flex-direction: column;

	a {
		line-height: 30px;

		&:hover {
			color: $color-primary;
		}
	}
}

.info,
.extra {
	width: 19%;
	padding-left: 36px;
}

.title {
	margin-bottom: 20px;
	line-height: 18px;
	font-size: 17px;
	font-weight: 500;
}

.newsletter {
	width: 31%;

	p {
		margin-bottom: 22px;
	}
}

.input {
	position: relative;
	width: 100%;
	height: 46px;

	input {
		padding: 0 120px 0 20px;
		width: 100%;
		height: 100%;
		line-height: 22px;
		border: 1px solid #ededed;
		border-radius: 30px;

		&::placeholder {
			letter-spacing: normal;
			line-height: normal;
			font-size: 14px;
			font-family: "Work Sans", sans-serif;
		}
	}
}
