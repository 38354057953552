@import '../../../styles/vars';

.popover {
	position: absolute;
	top: calc(100% - 2px);
	right: 100px;
	display: grid;
	padding: 20px 20px 20px 30px;
	height: auto;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	border-radius: 3px;
	border: 1px solid $color-border;
	background-color: $color-white;
	box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
	z-index: $z-index-1;
	@include transition-all;

	&.active {
		visibility: visible;
		opacity: 1;
		z-index: $z-index100;
		max-height: calc(100vh - 200px);
	}
}
