@import '../../shared/styles/vars';

.section {
	background-color: $color-section-bg;
}

.wrapper {
	display: flex;
	gap: 25px;
}

.left {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 32.8251%;
}

.right {
	width: 65.7020%;
}
