// colors
$color-white: #FFFFFF;
$color-black: #000000;
$color-primary: #40A944;
$color-primary-40: lighten($color-primary, 40%);
$color-secondary: #FC8A35;
$color-error: #FF7063;
$color-warning: #F3CD01;
$color-dark: #222222;
$color-dark2: #333333;
$color-dark3: #555555;
$color-dark4: #666666;
$color-grey: #ACACAC;
$color-grey2: #F0F0F0;
$color-grey3: #f7f6f7;
$color-border: #EBEBEB;
$color-border2: #EDEDED;
$color-border3: #DDDDDD;
$color-border4: #E8E8E8;
$color-border5: #3D3E40;
$color-border-sort: #999999;
$color-border-focus: darken($color-border3, 25%);
$color-border-hover: #AEAEAE;
$separator: #EDEDED;
$color-section-bg: #F4F4F4FF;
$color-pagination-item-background: #F1F1F1;
$color-sort: #F6F6F6;
$color-timer-border: #E1E1E1;
$color-slider-range: #DBDBDB;
$color-modal-bg: rgba($color-dark, .58);

// radiuses
$default-border-radius: 3px;
$border-radius-md: 5px;
$border-radius-lg: 10px;

// colors with opacity
$color-modal-border: rgba(0, 0, 0, .6);

// weights
$weight-normal: 400;
$weight-medium: 500;
$weight-semi: 600;
$weight-bold: 700;

// fonts
$WorkSans: 'Work Sans', sans-serif;
$PlayfairDisplay: 'Playfair Display', serif;

// transitions
@mixin transition-all() {
	transition: all 0.3s ease;
}

@mixin transition-sort() {
	transition: all 0.2s ease-in-out;
}

@mixin transition-offer() {
	transition: all 900ms linear;
}

// z-indexes
$z-index100: 100;
$z-index90: 90;
$z-index10: 10;
$z-index-1: -1;

// transitions
$transition-opacity: opacity .18s ease-in;
$transition-tooltip: opacity .18s ease-in-out;
