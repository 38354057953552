.layout {
	display: flex;
	justify-content: space-between;
	gap: 25px;
}
.main {
	width: 75%;
}
.aside {
	width: 25%;
	height: auto;
}
