@import '../../../shared/styles/vars';

.product_tower {
	display: grid;
	gap: 30px;
	padding: 0 10px;
}
.product {
	margin-bottom: 0;

	& > a + div {
		align-self: center;
	}
}
