.wrapper {
	position: relative;
	padding-bottom: 70px;
	width: 100%;
	height: 100%;
}

.sidebar {
	position: sticky;
	top: 60px;
	display: grid;
	gap: 28px;
}
