@import '../../../../shared/styles/vars';

.tags {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0;
    max-width: 340px;
    width: 100%;
}
.tag {
    text-align: center;
    text-transform: uppercase;
    min-width: 40px;
    padding: 0 4px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 5px;
    color: $color-white;
    background: $color-primary;
}
