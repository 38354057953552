@import '../../../shared/styles/vars';

.section {
	background-color: $color-section-bg;
}

.slider_bg {
	background-color: $color-section-bg;
}

.post {
	margin: 0 15px;
}
