@import '../../../styles/vars';

.slider {
	padding: 30px 0 20px;
	border-radius: 10px;
	background-color: $color-white;
}

.title {
	text-transform: capitalize;
	padding: 0 20px;
	line-height: 26px;
	font-size: 24px;
	font-weight: 500;
	color: $color-dark;
}

.arrow {
	position: absolute;
	top: -50px;
	right: -735px;
	display: inline-flex;
	padding: 0;
	width: 22px;
	height: 26px;
}

.arrow_prev {
	transform: scale(-1) translateX(100%);
}
