@import '../../../styles/vars';

.section {
	margin-bottom: 70px;
	padding: 58px 0;
	background: url('../../../../assets/images/breadcrumb/1.jpg') no-repeat 100% 50%;
}
.content {
	display: grid;
	justify-content: center;
	justify-items: center;
}
.page {
	text-transform: capitalize;
	line-height: 50px;
	font-size: 42px;
	font-weight: $weight-normal;
}
.breadcrumbs {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	gap: 7px;
}
.breadcrumb,
.page_breadcrumb {
	text-transform: capitalize;
	line-height: 24px;
	font-size: 14px;
}
.breadcrumb {
	cursor: pointer;

	&.link:hover {
		color: $color-primary;
	}
}
.category {
	display: flex;
	align-items: center;
	gap: 7px;
}
