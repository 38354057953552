@import '../../../styles/vars';

.box {
	display: grid;
	width: 100%;
}
.title {
	text-transform: capitalize;
	margin-bottom: 8px;
	line-height: 36px;
	font-size: 18px;
	font-weight: $weight-medium;
}
.text {
	line-height: 24px;
}
.rating_text {
	text-transform: capitalize;
	line-height: 30px;
	font-weight: $weight-bold;
}
.required {
	&::after {
		margin-left: 4px;
		content: '*';
		color: $color-primary;
	}
}
.new_rating {
	justify-self: flex-start;
}
.form {
	width: 100%;
}
.textarea {
	textarea {
		margin-bottom: 20px;
		padding: 5px 20px 0;
		height: 120px;
	}
}
.btn_auth_text {
	text-transform: uppercase;
	font-size: 13px;
	line-height: 26px;
	font-weight: $weight-medium;
}
