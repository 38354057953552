@import '../../styles/vars';

.box {
	display: flex;
	justify-content: center;
	align-items: baseline;
	gap: 10px;
}
.price {
	line-height: 20px;
	font-size: 17px;

	&.default {
		text-decoration: line-through;
		line-height: 18px;
		font-size: 15px;
		font-weight: $weight-normal;
	}
}
.discount_price {
	line-height: 20px;
	font-size: 17px;
	font-weight: $weight-medium;
	color: $color-primary;
}

// sizes
.large {
	.price {
		line-height: 27px;
		font-size: 23px;
	}
	.old {
		line-height: 24px;
		font-size: 20px;
	}
	.discount_price {
		line-height: 27px;
		font-size: 23px;
	}
}
