@import '../../../../shared/styles/vars';

.box {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.label {
	position: relative;

	&.required {
		&::after {
			margin-left: 4px;
			content: '*';
			color: $color-primary;
		}
	}

	&.active {
		&::before {
			border-color: $color-primary;
		}

		&::after {
			background-color: $color-primary;
		}
	}

	&:hover {
		&::before {
			border-color: $color-primary;
		}
	}
}

.textarea {
	padding: 10px;
	width: 100%;
	height: 100%;
	resize: none;
	border: 1px solid $color-border3;
	@include transition-all;

	&::placeholder {
		letter-spacing: normal;
		line-height: normal;
		font-size: 14px;
		font-family: 'Work Sans', sans-serif;
	}

	&:focus {
		border-color: $color-primary;
	}

	&.error {
		border-color: $color-error;
	}

	&:hover {
		border-color: $color-border-focus;
	}

	&:focus {
		border-color: $color-border-focus;

		&::placeholder {
			opacity: 0;
		}
	}
}
.error_popover {
	transform: translateX(106%) translateY(calc(-50% + 12px)) !important;
	right: -30px !important;
}
