@import '../../../../shared/styles/vars';

.categories {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 25px;
	max-width: 268px;
	width: 100%;
	padding: 0 17px 0 20px;
	text-transform: capitalize;
	line-height: 50px;
	font-size: 16px;
	font-weight: 500;
	border-radius: 10px 10px 0 0;
	cursor: pointer;
	color: $color-white;
	background-color: $color-primary;
	z-index: 100;
}

.burger {
	display: flex;
	flex-direction: column;
	gap: 3px;
	margin-right: 20px;
	width: 17px;

	span {
		width: 100%;
		height: 2px;
		background-color: $color-white;
	}
}

.svg {
	margin-left: auto;
	width: 15px;
	height: 15px;
	transform: rotate(90deg);

	path {
		fill: $color-white;
	}
}

.popup {
	position: absolute;
	top: 50px;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	padding: 10px 0;
	border-radius: 3px;
	cursor: default;
	border: 1px solid $color-border2;
	background-color: $color-white;
	box-shadow: 0 0 5.76px 0.24px rgb(0 0 0 / 10%);
	transition: .5s opacity, .55s max-height ease-out;
	z-index: 200;

	&.active {
		max-height: 800px;
		visibility: visible;
		opacity: 1;
		z-index: $z-index100;
	}
}

.prime_category {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 38px;
	color: $color-dark;
	cursor: pointer;
}
.link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: capitalize;
	line-height: 22px;
	padding: 8px 20px 8px 30px;
	min-height: 100%;
	width: 100%;
	height: 100%;
	font-size: 14px;
	font-weight: $weight-normal;
	@include transition-all;

	&:hover {
		color: $color-primary;

		.svg_subcategories path {
			fill: $color-primary;
		}
	}
}
.svg_subcategories {
	width: 15px;
	height: 15px;
}
