@import '../../../styles/vars';

.menu {
	display: flex;
	align-items: center;
	gap: 4px;
	position: absolute;
	padding: 4px 8px;
	border-radius: 30px;
	background-color: $color-white;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	cursor: default;
	z-index: -10;
	opacity: 0;
	@include transition-all;

	&.active {
		opacity: 1;
		z-index: $z-index90;
		transform: translateY(0) translateX(-50%);
	}

	&_xs {
		bottom: -12px;
		left: 0;
		transform: translateY(-20px);

		&.active {
			transform: translateY(0);
		}
	}

	&_sm {
		top: -75px;
		left: 50%;
		transform: translateY(10px) translateX(-50%);
	}

	&_md-lg {
		top: -80px;
		left: 50%;
		transform: translateY(15px) translateX(-50%);
	}

	&_lg {
		top: -80px;
		left: 50%;
		transform: translateY(15px) translateX(-50%);
	}

	&_row {
		gap: 10px;
		margin-top: 22px;
		padding: 0;
		box-shadow: none;
		opacity: 1;
		z-index: $z-index90;
	}
}

.button_cart_text {
	text-transform: capitalize;
	line-height: 40px;
}

.btn_popup {
	z-index: $z-index-1;

	.compare_svg {
		width: 18px;
		height: 18px;
	}
}
