@import '../../shared/styles/vars';

.post {
	display: flex;
	flex-direction: column;

	&_row-xs {
		flex-direction: row;
		align-items: center;
		gap: 15px;

		.img_link {
			width: 40px;
			height: 40px;
		}

		.info {
			flex-direction: column-reverse;
			padding: 0;
		}

		.title {
			margin-bottom: 0;
			line-height: 15px;
			font-size: 15px;
			font-weight: $weight-normal;
		}

		.meta {
			margin-bottom: 0;
		}
	}
}

.img_link {
	max-width: 362px;
	@include transition-all;

	&:hover {
		opacity: 0.8;
	}
}

.img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 23px 20px 20px;
	background-color: $color-white;
}

.meta {
	margin-bottom: 9px;
}

.title {
	margin-bottom: 17px;
	text-transform: capitalize;
	line-height: 18px;
	font-size: 17px;
	font-weight: $weight-medium;
	color: $color-dark;
	@include transition-all;

	& > :hover {
		color: $color-primary;
	}
}

.category {
	color: $color-dark;
	@include transition-all;

	&:hover {
		color: $color-primary;
	}
}
