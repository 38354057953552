@import '../../../../shared/styles/vars';

.posts {
	display: grid;
	gap: 15px;
}

.post {
	display: flex;
	gap: 10px;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.block {
	margin-left: 10px;
	width: 100%;
}

.img {
	flex: 0 0 60px;
	height: 42px;
}

.title {
	text-transform: capitalize;
	line-height: 14px;
	font-size: 13px;
	font-weight: 500;
	color: $color-dark;
	@include transition-all;

	&:hover {
		color: $color-primary;
	}
}

.date {
	line-height: 24px;
	font-size: 12px;
	color: $color-dark;
}
