@import '../../../../shared/styles/vars';

.navbar {
	position: relative;

	&__border {
		position: absolute;
		top: 38px;
		left: 0;
		right: 0;
		height: 1px;
		background-color: $color-border2;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 39px;
	}

	&__center {
		display: flex;
		align-items: center;
		padding: 30px 0 24px;
		height: 100px;

		&.padding {
			padding-bottom: 50px;
		}
	}
}

/* top start */
.meta {
	display: flex;

	&__divider {
		margin: 0 19px;
		line-height: 39px;
	}
}

/* center start */
.center {
	display: flex;
}

.search {
	margin-right: 118px;
	margin-left: auto;
	max-width: 462px;
	width: 100%;
}

.auth {
	margin-right: 20px;

	&__link {
		text-transform: uppercase;

		&:hover {
			color: $color-primary;
		}
	}

	&__divider {
		margin: 0 7px;
	}
}

.account {
	position: relative;
	display: flex;
	align-items: center;
	gap: 20px;

	&_link {
		position: relative;
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		gap: 5px;

		&:hover svg:not(.compare_link) path {
			fill: $color-primary;
		}

		&:hover svg.compare_link path {
			stroke: $color-primary;
		}
	}
}

.profile_nav {
	margin-right: 6px;
}

.count {
	display: block;
	width: 18px;
	height: 18px;
	text-align: center;
	line-height: 18px;
	font-size: 12px;
	border-radius: 50%;
	background-color: $color-border2;
}
