@import 'shared/styles/reset';
@import './shared/styles/vars';
@import './shared/styles/grid';
@import './shared/styles/ui';
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Work+Sans:wght@300;400;500;600;700&display=swap');

body {
	font-family: 'Work Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
	'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 24px;
	font-size: 14px;
	color: $color-dark;
	letter-spacing: 0.02em;
}

a {
	display: inline-block;
	text-decoration: none;
	color: $color-dark;
	@include transition-all;

	&:focus {
		outline: none;
	}

	&:active {
		outline: none;
	}
}

svg path {
	@include transition-all;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	letter-spacing: 0.02em;
	font-family: 'Work Sans', serif;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}
