@import '../../shared/styles/vars';

.block {
	margin-top: 60px;
	margin-bottom: 65px;
}
.title {
	margin-bottom: 20px;
	text-transform: uppercase;
	line-height: 20px;
	font-size: 15px;
	font-weight: $weight-medium;
}
.comments {
	display: grid;
	gap: 30px;
	margin-left: -50px;
}
.icon {
	margin-bottom: -2px;
	width: 15px;
	height: 14px;
}
