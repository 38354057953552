@import '../../../styles/vars';

.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $z-index100;
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $color-modal-bg;
	z-index: $z-index10;
}
.box {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}
.wrapper {
	position: relative;
	padding: 30px 7px 40px;
	max-width: 1100px;
	width: 100%;
	background: $color-white;
	border: 1px solid $color-modal-border;
	z-index: $z-index100;
}
.wrapper::-webkit-scrollbar {
	width: 0;
	height: 0;
	appearance: none;
}
.btn_close {
	position: absolute;
	top: 10px;
	right: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	background-color: $color-grey2;
	border-radius: 50%;

	.close_svg {
		width: 11px;
		height: 11px;

		path {
			@include transition-all;
		}
	}

	&:hover {
		.close_svg path {
			fill: $color-primary;
		}
	}
}
