@import '../../../styles/vars';

.logout {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: start;
	gap: 15px;
	padding-top: 10px;
	font-size: 13px;
	color: $color-dark2;
	border-top: 1px solid $color-border;
	@include transition-all;
	cursor: pointer;

	&:hover {
		.icon {
			transform: translateX(3px);
		}
	}
}
.icon {
	width: 13px;
	height: 11px;
	@include transition-all;

	path {
		fill: $color-dark2;
	}
}
