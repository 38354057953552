@import '../../../../shared/styles/vars';

.list {
	position: relative;
	display: flex;
	align-items: center;
	line-height: 39px;
	cursor: pointer;
	@include transition-all;

	&:hover {
		color: $color-primary;

		.svg {
			fill: $color-primary;
		}
	}
}

.svg {
	margin-top: -3px;
	margin-left: 5px;
	width: 13px;
	height: 15px;
	transform: rotate(90deg);
}

.popup {
	position: absolute;
	top: 38px;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-height: 0;
	opacity: 0;
	visibility: hidden;
	padding: 10px 20px;
	border-radius: 3px;
	cursor: default;
	border: 1px solid $color-border2;
	background-color: $color-white;
	transition: .35s opacity, .41s visibility, .55s max-height;

	&:hover .popup {
		max-height: 200px;
		visibility: visible;
		opacity: 1;
	}
}

.item {
	width: 100%;
	line-height: 28px;
	font-size: 13px;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		color: $color-primary;
	}
}
