@import '../../../../../styles/vars';

.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.image {
	margin-bottom: 25px;
	width: 250px;
	height: 220px;
	opacity: .85;
}

.title {
	margin-bottom: 13px;
	text-transform: capitalize;
	font-size: 26px;
	font-weight: $weight-bold;
}

.text {
	margin-bottom: 25px;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
}

.link {
	color: $color-primary;
}

.actions {
	display: flex;
	gap: 20px;
}

.button_text {
	color: $color-white;
}
