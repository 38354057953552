@import '../../shared/styles/vars';

.reply {
	width: 100%;

	&_short {
		margin-top: -15px;
	}
}
.title {
	margin-bottom: 20px;
	text-transform: uppercase;
	line-height: 20px;
	font-size: 15px;
	font-weight: $weight-medium;
}
.user_actions {
	display: grid;
	gap: 20px;
}
.message_icon > svg {
	width: 15px;
	height: 14px;
}
