@import '../../../../../shared/styles/vars';

.nav_sub {
	position: absolute;
	top: 10px;
	left: 100%;
	display: grid;
	grid-template-columns: auto auto;
	gap: 30px;
	padding: 25px 20px 23px;
	max-height: 400px;
	background-color: $color-white;
	cursor: default;
	opacity: 0;
	z-index: $z-index-1;
	transform: translateX(40px);
	@include transition-all;

	&.active {
		opacity: 1;
		z-index: $z-index100;
		transform: translateX(0);
	}
}
.sub_category_item {
	display: grid;
	align-content: flex-start;
}
.sub_category_title {
	display: flex;
	justify-self: flex-start;
	text-transform: uppercase;
	line-height: 30px;
	font-size: 14px;
	font-weight: $weight-medium;
	color: $color-dark;
	cursor: pointer;
	@include transition-all;

	&:hover {
		color: $color-primary;
	}
}
.sub_category_link {
	text-transform: capitalize;
	white-space: nowrap;
	width: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: $weight-normal;
	color: $color-dark;
	cursor: pointer;
	@include transition-all;

	&:hover {
		color: $color-primary;
	}
}
