@import '../../shared/styles/vars';

.product {
	position: relative;
	display: flex;
	align-items: flex-start;
	margin-bottom: 25px;

	.img_link {
		display: flex;
		margin-right: 16px;
		min-width: 120px;
		width: 120px;
		height: 120px;
		@include transition-all;

		&:hover {
			opacity: 0.8;
		}
	}
	.img {
		width: 100%;
		height: 100%;
	}

	.info {
		position: relative;
		justify-content: flex-start;
	}

	.name {
		text-transform: capitalize;
		margin-bottom: 18px;
		line-height: 15px;
		font-size: 15px;
		font-weight: $weight-normal;
		color: $color-black;
	}

	.subCategory {
		display: grid;
		grid-auto-flow: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
		line-height: 12px;
		font-size: 12px;
		z-index: 50;
		font-weight: $weight-normal;

		&_divider {
			margin: 0 2px;
			color: $color-dark4;
		}

		& button {
			@include transition-all;
		}
	}

	.name > a:hover,
	.subCategory > button:hover {
		color: $color-primary;
	}

	&_xs {
		.subCategory {
			justify-content: flex-start;
		}
	}

	&_sm {
		flex-direction: column;

		& .img_link {
			margin-bottom: 16px;
			width: 100%;
			min-height: 225px;
		}

		& .info {
			margin-left: 0;
			text-align: center;
		}

		& .name {
			margin-bottom: 16px;
		}

		& .subCategory {
			margin-bottom: 12px;
		}
	}

	&_md-lg {
		flex-direction: column;

		& .img_link {
			margin-bottom: 16px;
			width: 100%;
			height: 100%;
		}

		& .info {
			width: 100%;
			margin-left: 0;
			text-align: center;
		}
	}

	// sizes
	&_lg {
		flex-direction: column;

		& .img_link {
			margin-bottom: 16px;
			width: 100%;
			height: 100%;
		}

		& .info {
			width: 100%;
			margin-left: 0;
			text-align: center;
		}

		& .name {
			margin-bottom: 16px;

			&.margin_less {
				margin-bottom: 10px;
			}
		}

		& .subCategory {
			margin-bottom: 12px;

			&.subCategory_name {
				margin-right: 2px;
			}

			&.margin_less {
				margin-bottom: 9px;
			}
		}
	}

	&_row {
		gap: 25px;

		&:not(:last-child) {
			margin-bottom: 0;
		}
		& .img_link {
			margin-right: 0;
			width: 262px;
			height: 262px;
		}
		.info {
			text-align: start;
			width: calc(100% - 287px);
		}
		& .name {
			margin-bottom: 16px;
			font-size: 18px;
		}
		& .subCategory {
			justify-content: start;
			margin-bottom: 12px;
		}
		& .prices {
			margin-bottom: 15px;
		}
		& .description {
			width: auto;
		}
	}

	&_row-xs {
		margin-bottom: 0;
		align-items: center;

		& .img_link {
			width: 40px;
			height: 40px;
			min-width: 40px;
		}

		.name {
			margin-bottom: 0;
		}
	}
}

.prices {
	opacity: 1;
	@include transition-all;

	&.hide {
		opacity: 0;
	}
}

.flex_start {
	justify-content: flex-start;
}
