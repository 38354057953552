@import '../../../../styles/vars';

.header {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.subtitle {
	line-height: 24px;
	font-size: 15px;
	font-style: italic;
	font-family: $PlayfairDisplay;
	color: $color-dark;
}

.title {
	text-transform: capitalize;
	line-height: 35px;
	font-size: 32px;
	font-weight: $weight-medium;
	color: $color-dark;
}
