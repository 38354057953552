@import '../../../../shared/styles/vars';

.comments {
	display: grid;
	gap: 15px;
}

.comment {
	display: flex;
	gap: 14px;
}

.img_link {
	width: 50px;
	height: 50px;
}

.img {
	width: inherit;
	height: inherit;
	border-radius: 50%;
	object-fit: cover;
}

.message {
	padding-top: 2px;
}

.name {
	line-height: inherit;
	color: $color-primary;
}

.says {
	font-size: 12px;
}

.text {
	display: inline;
	font-size: 14px;
	@include transition-all;

	&:hover {
		color: $color-primary;
	}
}
